<template>
    <ion-page>
        <ion-content>
          <paid-component/>
            <home-banner/>
        </ion-content>
    </ion-page>
</template>

<script>
    import {defineComponent} from 'vue';
    import HomeBanner from "../components/HomeBanner";
    import {IonPage, IonContent,} from "@ionic/vue";
    import PaidComponent from "../components/PaidComponent.vue";

    export default defineComponent({
        name: 'Home',
        components: {
          PaidComponent,
            HomeBanner,
            IonPage, IonContent
        },




    });

</script>

<style scoped>
</style>
