<template>
  <div class="site-content">
    <div class="landing-banner" style="background-color: #56B35A;">
      <div class="container">
        <div class="lb-info">
          <h2 style="color: #ffffff">Scrapl.us</h2>
          <p style="color: #ffffff" class="d-none d-lg-block">Mobil uygulamamız için aşağıda bulunan linkleri
            kullanabilirsiniz!</p>
          <div class="lb-button d-none d-lg-block">
            <a href="https://apps.apple.com/us/app/scrapl-us-hurda-pazar%C4%B1/id6467519628" title="App store"><img src="assets/app-store.png"
                                               alt="App store"></a>
            <a v-if="!isPlatform('ipad')" href="https://play.google.com/store/apps/details?id=com.scraplus.app" title="Google play"><img src="assets/google-play.png"
                                                 alt="Google play"></a>
          </div>
        </div><!-- .lb-info -->
      </div>
    </div><!-- .landing-banner -->
    <div class="img-box-inner">
      <div class="container">
        <div class="title ld-title">
          <h2>Scrapl.us nedir?</h2>
          <p>Scraplus sayesinde tüm hurda satın alma ve satış ilanlarınızı görüntületebilir ve pazara ortak ve haberdar
            olabilirsiniz!</p>
        </div>
      </div>
    </div><!-- .img-box-inner -->
    <div class="features-inner" style="background-color: rgba(86,179,90,0.65);">
      <div class="container">
        <div class="title ld-title">
          <h2 style="color: #ffffff">Scrapl.us'ta neler var?</h2>
          <p style="color: #ffffff">Scraplus uygulamasında olduğunuz bölgenin içinde açılan yeni satın alma veya satış
            ilanlarının bildirimleri
            sms, e-posta yada bildirim olarak alabilir ve bu bildirimler sayesinde piyasada işlerinizi
            arttırabilirsiniz.</p>
        </div><!-- .title -->
        <!-- .features-item -->
      </div>
    </div><!-- .features -->
    <div class="features-inner">
      <div class="container">
        <div class="features-item">
          <div class="features-thumb">
            <!--img src="https://golohtml.uxper.co/demo/images/features-01.jpg" alt="Trending Ui/Ux Design"-->
          </div>
          <div class="features-info">
            <h3>Yeni, Hızla büyüyen <br> <span>Hurda İlan</span> Pazarı</h3>
            <p>Bu piyasada hızlıca sende yerini kap ve bölgendeki tüm piyasaya hakim ol. </p>
          </div>
        </div><!-- .features-item -->
      </div><!-- .features-item -->
    </div>
    <div class="landing-banner" style="background-color: #56b35a;">
      <div class="container">
        <div class="lb-info">
          <h2 style="color: #ffffff">Uygulamaya kayıt ol!</h2>
          <p style="color: #ffffff">Uygulamaya giriş yapmak yada kayıt olmak için yandaki menüyü kullan ve piyasadaki iş
            sayını arttır!</p>
        </div><!-- .lb-info -->
      </div>
    </div><!-- .landing-banner -->
  </div>
</template>

<script>
import {isPlatform} from "@ionic/vue";

export default {
  name: "HomeBanner",
  methods: {isPlatform},
}
</script>

<style scoped>

</style>